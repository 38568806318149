/* eslint-disable vue/max-attributes-per-line */ /* eslint-disable */
<template>
  <div>
    <ValidationObserver v-slot="context">
      <vx-card class="mb-base">
        <form @submit.prevent="submit(context)">
          <FormLayout>
            <FormLabel>
              <span>ชื่อสไตล์บ้าน</span><span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="ชื่อสไตล์บ้าน"
                rules="required"
              >
                <vs-input
                  v-model="formData.house_serie_title"
                  class="w-full"
                  name="house_serie_title"
                  placeholder="กรอกชื่อ Series"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รายละเอียดสไตล์บ้าน</span><span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="รายละเอียดสไตล์บ้าน"
                rules="required"
              >
                <vs-textarea
                  :class="{ 'border border-danger': errors.length > 0 }"
                  v-model="formData.house_serie_description"
                  rows="8"
                  label="รายละเอียดสไตล์บ้าน"
                  class="w-full"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>
                โลโก้สไตล์บ้าน<span class="text-danger"> *</span>
                <br >(.png)
                <br>
                <span>ขนาดรูปไม่เกิน 150 x 150 px</span>
              </span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="house_serie_logo"
                rules="required|lh_mimes:jpg,png,gif|lh_image_max_resolution:150,150"
              >
                <ImageUpload
                  v-model="formData.house_serie_logo"
                  :has-delete-button="!isEmpty(formData.house_serie_logo)"
                  :folder="`${baseFolder}/house-serie/${formData.house_serie_folder_name}/Logo`"
                  selection-name="house_serie_logo"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>
                ภาพตัวแทนสไตล์บ้าน<span class="text-danger"> *</span>
                <br >(.jpg , .png)
                <br >
                <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
                <br>
                <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
              </span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="ภาพตัวแทนสไตล์บ้าน"
                rules="required|lh_mimes:jpg,png,gif|lh_ratio:16,9"
              >
                <ImageUpload
                  v-model="formData.house_serie_thumbnail"
                  :has-delete-button="!isEmpty(formData.house_serie_thumbnail)"
                  :folder="`${baseFolder}/house-serie/${formData.house_serie_folder_name}/Thumbnail`"
                  selection-name="house_serie_thumbnail"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>URL 360 Virtual Tour</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="URL 360 Virtual Tour"
              >
                <vs-input
                  :class="{ 'border border-danger': errors.length > 0 }"
                  v-model="formData.house_serie_image_360_url"
                  class="w-full"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>
                เลือกภาพประกอบ 360 Virtual Tour 
                <br >(.jpg , .png , .gif)
                <br >
                <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
                <br>
                <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
              </span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="house_serie_image_360_thumbnail"
                rules="lh_mimes:jpg,png,gif|lh_ratio:16,9"
              >
                <ImageUpload
                  v-model="formData.house_serie_image_360_thumbnail"
                  :folder="`${baseFolder}/house-serie/${formData.house_serie_folder_name}/360`"
                  :has-delete-button="!isEmpty(formData.house_serie_image_360_thumbnail)"
                  selection-name="house_serie_image_360_thumbnail"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>เลือกชนิดการอัพโหลด</span>
            </FormLabel>
            <FormContent>
              <ul>
                <li>
                  <vs-radio
                    v-model="formData.house_serie_video_type"
                    vs-name="radios1"
                    vs-value="youtube"
                  >VDO Youtube</vs-radio
                  >
                </li>
                <li>
                  <vs-radio
                    v-model="formData.house_serie_video_type"
                    vs-name="radios1"
                    vs-value="file"
                  >VDO File</vs-radio
                  >
                </li>
              </ul>
            </FormContent>
          </FormLayout>
          <FormLayout v-show="formData.house_serie_video_type === 'youtube'">
            <FormLabel>
              <span>URL Youtube</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${
                  formData.house_serie_video_type === 'youtube' ? 'url' : ''
                }`"
                name="URL Youtube"
              >
                <vs-input
                  v-model="formData.house_serie_video_url"
                  class="w-full"
                  placeholder="URL Youtube"
                  name="house_serie_video_url"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>
                Thumbnail
                <br >(.jpg , .png , .gif)
                <br >
                <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
                <br>
                <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
              </span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="house_serie_video_thumbnail"
                rules="lh_mimes:jpg,png,gif|lh_ratio:16,9"
              >
                <ImageUpload
                  v-model="formData.house_serie_video_thumbnail"
                  :folder="`${baseFolder}/house-serie/${formData.house_serie_folder_name}/VDO Thumbnail`"
                  :has-delete-button="!isEmpty(formData.house_serie_video_thumbnail)"
                  selection-name="house_serie_video_thumbnail"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout v-show="formData.house_serie_video_type === 'file'">
            <FormLabel>
              <span>เลือก VDO (.mp4)</span>
              <br><span class="font-bold">ขนาดไฟล์ vdo ไม่เกิน 300 MB</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="Video"
                rules="lh_mimes:mp4|lh_size:300_MB"
              >
                <VdoUpload
                  v-model="formData.house_serie_video_file"
                  :has-delete-button="!isEmpty(formData.house_serie_video_file)"
                  :folder="`${baseFolder}/house-serie/${formData.house_serie_folder_name}/VDO`"
                  selection-name="assets"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <vs-divider />
          <FormLayout>
            <FormLabel>
              <span>แสดงบนเว็ปไซต์</span><span class="text-danger"> *</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <vs-switch v-model="formData.status" />
            </div>
          </FormLayout>
        </form>
      </vx-card>
      <vx-card>
        <FormLayout>
          <FormLabel>
            <span>แก้ไขล่าสุดโดย</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formData.updated_by}}</span>
          </div>
        </FormLayout>
        <FormLayout>
          <FormLabel>
            <span>แก้ไขล่าสุดวันที่</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
          </div>
        </FormLayout>
        <FormLayout>
          <FormLabel>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <vs-button 
              class="mr-2" 
              @click="submit(context)">{{ submitBtnLabel }}
            </vs-button>
          </div>
        </FormLayout>
      </vx-card>
    </ValidationObserver>
  </div>
</template>
<script>
import { onMounted, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import get from 'lodash/get'
// import keyBy from 'lodash/keyBy'
import isEmpty from 'lodash/isEmpty'
import ImageUpload from '@/components/upload/ImageUpload'
import VdoUpload from '@/components/upload/VdoUpload'
import useCrud from '@/use/useCrud'
// import { createFolder } from '@/helper/damUtils'
import useHouseSerieUtil from '@/use/useHouseSerieUtil'
import env from '@/env'

// import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'HomeSeriesView',
  components: {
    VdoUpload,
    ImageUpload,
    ValidationObserver,
  },
  setup(props, ctx) {
    const crudForm = useCrud(ctx, 'houseSerie')
    crudForm.routePrefix.value = 'property-plan-home-series'
    // const lang = ctx.root.$store.state.locale.currentLang

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)
    const submitBtnLabel = computed(() => {
      const uuid = get(ctx.root.$router.currentRoute.params, 'id', null)

      if (uuid) {
        return "Update"
      } else {
        return "Submit"
      }
    })

    const { formData, fetchByRouteId, serializeFormData } = useHouseSerieUtil(ctx)
    
    const submit = (ctx) => {
      const serialzedData = serializeFormData(formData)
      crudForm.addItem(ctx, serialzedData)
    }

    onMounted(async () => {
      await fetchByRouteId()
    })

    return {
      ...crudForm,
      submit,
      formData,
      isEmpty,
      baseFolder,
      submitBtnLabel
    }
  },
}
</script>
