var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(context){return [_c('vx-card',{staticClass:"mb-base"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit(context)}}},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ชื่อสไตล์บ้าน")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ชื่อสไตล์บ้าน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"house_serie_title","placeholder":"กรอกชื่อ Series"},model:{value:(_vm.formData.house_serie_title),callback:function ($$v) {_vm.$set(_vm.formData, "house_serie_title", $$v)},expression:"formData.house_serie_title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("รายละเอียดสไตล์บ้าน")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"รายละเอียดสไตล์บ้าน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",class:{ 'border border-danger': errors.length > 0 },attrs:{"rows":"8","label":"รายละเอียดสไตล์บ้าน"},model:{value:(_vm.formData.house_serie_description),callback:function ($$v) {_vm.$set(_vm.formData, "house_serie_description", $$v)},expression:"formData.house_serie_description"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("\n              โลโก้สไตล์บ้าน"),_c('span',{staticClass:"text-danger"},[_vm._v(" *")]),_c('br'),_vm._v("(.png)\n              "),_c('br'),_c('span',[_vm._v("ขนาดรูปไม่เกิน 150 x 150 px")])])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"house_serie_logo","rules":"required|lh_mimes:jpg,png,gif|lh_image_max_resolution:150,150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"has-delete-button":!_vm.isEmpty(_vm.formData.house_serie_logo),"folder":(_vm.baseFolder + "/house-serie/" + (_vm.formData.house_serie_folder_name) + "/Logo"),"selection-name":"house_serie_logo"},model:{value:(_vm.formData.house_serie_logo),callback:function ($$v) {_vm.$set(_vm.formData, "house_serie_logo", $$v)},expression:"formData.house_serie_logo"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("\n              ภาพตัวแทนสไตล์บ้าน"),_c('span',{staticClass:"text-danger"},[_vm._v(" *")]),_c('br'),_vm._v("(.jpg , .png)\n              "),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ภาพตัวแทนสไตล์บ้าน","rules":"required|lh_mimes:jpg,png,gif|lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"has-delete-button":!_vm.isEmpty(_vm.formData.house_serie_thumbnail),"folder":(_vm.baseFolder + "/house-serie/" + (_vm.formData.house_serie_folder_name) + "/Thumbnail"),"selection-name":"house_serie_thumbnail"},model:{value:(_vm.formData.house_serie_thumbnail),callback:function ($$v) {_vm.$set(_vm.formData, "house_serie_thumbnail", $$v)},expression:"formData.house_serie_thumbnail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("URL 360 Virtual Tour")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"URL 360 Virtual Tour"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",class:{ 'border border-danger': errors.length > 0 },model:{value:(_vm.formData.house_serie_image_360_url),callback:function ($$v) {_vm.$set(_vm.formData, "house_serie_image_360_url", $$v)},expression:"formData.house_serie_image_360_url"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("\n              เลือกภาพประกอบ 360 Virtual Tour \n              "),_c('br'),_vm._v("(.jpg , .png , .gif)\n              "),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"house_serie_image_360_thumbnail","rules":"lh_mimes:jpg,png,gif|lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/house-serie/" + (_vm.formData.house_serie_folder_name) + "/360"),"has-delete-button":!_vm.isEmpty(_vm.formData.house_serie_image_360_thumbnail),"selection-name":"house_serie_image_360_thumbnail"},model:{value:(_vm.formData.house_serie_image_360_thumbnail),callback:function ($$v) {_vm.$set(_vm.formData, "house_serie_image_360_thumbnail", $$v)},expression:"formData.house_serie_image_360_thumbnail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("เลือกชนิดการอัพโหลด")])]),_c('FormContent',[_c('ul',[_c('li',[_c('vs-radio',{attrs:{"vs-name":"radios1","vs-value":"youtube"},model:{value:(_vm.formData.house_serie_video_type),callback:function ($$v) {_vm.$set(_vm.formData, "house_serie_video_type", $$v)},expression:"formData.house_serie_video_type"}},[_vm._v("VDO Youtube")])],1),_c('li',[_c('vs-radio',{attrs:{"vs-name":"radios1","vs-value":"file"},model:{value:(_vm.formData.house_serie_video_type),callback:function ($$v) {_vm.$set(_vm.formData, "house_serie_video_type", $$v)},expression:"formData.house_serie_video_type"}},[_vm._v("VDO File")])],1)])])],1),_c('FormLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.house_serie_video_type === 'youtube'),expression:"formData.house_serie_video_type === 'youtube'"}]},[_c('FormLabel',[_c('span',[_vm._v("URL Youtube")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"rules":("" + (_vm.formData.house_serie_video_type === 'youtube' ? 'url' : '')),"name":"URL Youtube"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"URL Youtube","name":"house_serie_video_url"},model:{value:(_vm.formData.house_serie_video_url),callback:function ($$v) {_vm.$set(_vm.formData, "house_serie_video_url", $$v)},expression:"formData.house_serie_video_url"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("\n              Thumbnail\n              "),_c('br'),_vm._v("(.jpg , .png , .gif)\n              "),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"house_serie_video_thumbnail","rules":"lh_mimes:jpg,png,gif|lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/house-serie/" + (_vm.formData.house_serie_folder_name) + "/VDO Thumbnail"),"has-delete-button":!_vm.isEmpty(_vm.formData.house_serie_video_thumbnail),"selection-name":"house_serie_video_thumbnail"},model:{value:(_vm.formData.house_serie_video_thumbnail),callback:function ($$v) {_vm.$set(_vm.formData, "house_serie_video_thumbnail", $$v)},expression:"formData.house_serie_video_thumbnail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.house_serie_video_type === 'file'),expression:"formData.house_serie_video_type === 'file'"}]},[_c('FormLabel',[_c('span',[_vm._v("เลือก VDO (.mp4)")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดไฟล์ vdo ไม่เกิน 300 MB")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Video","rules":"lh_mimes:mp4|lh_size:300_MB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('VdoUpload',{attrs:{"has-delete-button":!_vm.isEmpty(_vm.formData.house_serie_video_file),"folder":(_vm.baseFolder + "/house-serie/" + (_vm.formData.house_serie_folder_name) + "/VDO"),"selection-name":"assets"},model:{value:(_vm.formData.house_serie_video_file),callback:function ($$v) {_vm.$set(_vm.formData, "house_serie_video_file", $$v)},expression:"formData.house_serie_video_file"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('vs-divider'),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แสดงบนเว็ปไซต์")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('vs-switch',{model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}})],1)],1)],1)]),_c('vx-card',[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดโดย")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formData.updated_by))])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดวันที่")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.formData.updated_at)))])])],1),_c('FormLayout',[_c('FormLabel'),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('vs-button',{staticClass:"mr-2",on:{"click":function($event){return _vm.submit(context)}}},[_vm._v(_vm._s(_vm.submitBtnLabel)+"\n          ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }